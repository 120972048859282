// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osteopathie-js": () => import("./../../../src/pages/osteopathie.js" /* webpackChunkName: "component---src-pages-osteopathie-js" */),
  "component---src-pages-specialisaties-js": () => import("./../../../src/pages/specialisaties.js" /* webpackChunkName: "component---src-pages-specialisaties-js" */),
  "component---src-pages-therapeuten-js": () => import("./../../../src/pages/therapeuten.js" /* webpackChunkName: "component---src-pages-therapeuten-js" */)
}

